export const Bio = {
  name: "Marcus",
  roles: [
    "Student",
    "Product Builder",
    "System Optimizer",
    "AI Researcher",
    "0-1 Specialist",
  ],
  description:
    "As an Engineering student at Berkeley, I'm focused on applying my technical background to build generational products that have a real impact on real people. I'm passionate about transforming the way users experience life, connect with each other, and unlock their full potential in an increasingly digital world.",
  description2: "Outside of the office, the lab, and the classroom, I find deep joy in chess, snowboarding, and EDM.",

  github: "https://github.com/nicholasychua",
  resume:
    "https://drive.google.com/file/d/1zq7_4sBinIX63y2S2ps7iq32_P7L61vl/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/marcusyc/",
  twitter: "https://twitter.com/nicholasychua",
  insta: "https://www.instagram.com/nicholaschuas/",
  facebook: "https://www.facebook.com/nicholas.chua.58760",
  substack: "https://marcuschua.substack.com/",
  tableau: "https://public.tableau.com/app/profile/marcus",
};

export const skills = [
  {
    title: "Product ",
    skills: [
      {
        name: "GTM Strategy",
        image: "https://cdn-icons-png.flaticon.com/512/4363/4363846.png",
      },
      {
        name: "Advanced Analytics",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png",
      },
      {
        name: "Agile Management",
        image:
          "https://dovetail.com/logo.png",
      },
      {
        name: "AI & ML",
        image: "https://cdn-images-1.medium.com/max/1200/1*A6kkoOVJVpXPWewg8axc5w.png",
      },
      {
        name: "Documentation",
        image:
          "https://assets-global.website-files.com/62e11362da2667ac3d0e6ed5/659d7f9e582a15e81030a3cf_Mural_Symbol_Multicolor_RGB.png",
      },
      {
        name: "Roadmapping",
        image:
          "https://assets-global.website-files.com/62e11362da2667ac3d0e6ed5/659d7f9e582a15e81030a3cf_Mural_Symbol_Multicolor_RGB.png",
      },

    ],
  },
  {
    title: "Design",
    skills: [
      {
        name: "Figma",
        image: "https://www.svgrepo.com/show/452202/figma.svg",
      },
      {
        name: "Jira",
        image: "https://cdn.icon-icons.com/icons2/2699/PNG/512/atlassian_jira_logo_icon_170511.png",
      },
      {
        name: "Notion",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png",
      },
      {
        name: "Miro",
        image:
          "https://asset.brandfetch.io/idAnDTFapY/idYC5f2L1X.png",
      },
      {
        name: "UXR & UAT",
        image:
          "https://dovetail.com/logo.png",
      },
      {
        name: "Canva",
        image: "https://cdn-images-1.medium.com/max/1200/1*A6kkoOVJVpXPWewg8axc5w.png",
      },
      {
        name: "Mural",
        image:
          "https://assets-global.website-files.com/62e11362da2667ac3d0e6ed5/659d7f9e582a15e81030a3cf_Mural_Symbol_Multicolor_RGB.png",
      },
      {
        name: "Slack",
        image: "https://creazilla-store.fra1.digitaloceanspaces.com/icons/3237021/slack-icon-sm.png",
      },
    ],
  },
  {
    title: "Engineering",
    skills: [
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Next Js",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
      },
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
    ],
  },
  /*{
    title: "Other",
    skills: [
      {
        name: "Git",
        image:
          "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png  ",
      },
      {
        name: "GitHub",
        image:
          "https://cdn-icons-png.flaticon.com/512/25/25231.png",
      },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "Final Cut Pro X",
        image:
          "https://upload.wikimedia.org/wikipedia/en/9/9f/2015_Final_Cut_Pro_Logo.png",
      },
      {
        name: "Capcut",
        image:
          "https://seeklogo.com/images/C/capcut-logo-A52AD0DA7D-seeklogo.com.png",
      },
      {
        name: "Davinci Resolve",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/4/4d/DaVinci_Resolve_Studio.png",
      },
      {
        name: "Medium",
        image:
          "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Medium_svg5-512.png",
      },
      {
        name: "Substack",
        image:
          "https://substack.com/img/substack.png",
      },
      {
        name: "Typeform",
        image:
          "https://seeklogo.com/images/T/typeform-logo-A4FC2E0B41-seeklogo.com.png",
      },
    ],
  },*/
  {
    title: "Awards & Honors",
    skills: [
      {
        name: "Dean's List - College of Engineering",
        image:
          "https://media2.giphy.com/media/uaM9fxlVLA6b2Uh7ct/giphy.gif?cid=6c09b9526q7zgcd4mykz4mz18th0cqi4hljsdr4qpu22qt2h&ep=v1_stickers_related&rid=giphy.gif&ct=s",
      },
      {
        name: "Top 1% of Class",
        image: "https://freelogopng.com/images/all_img/1664035876new-meta-logo.png",
      },
      {
        name: "Venture Capital Investments Competition (VCIC) Regional Winner",
        image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      },
      {
        name: "Eagle Scout",
        image:
          "https://clubrunner.blob.core.windows.net/00000002427/PhotoAlbum/branding/Mark-of-Excellence-4992.png",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/erbt7yw7tsufiiokrspx",
    role: "Project Leader, AI Research",
    company: "Berkeley Operations & Behavioral Analytics Lab (BOBALAB)",
    date: "Sept 2023 - Present",
    desc: "Built AI products with HCI/UX research frameworks.",
    skills: [
      "Python",
      "Qualtrics",
      "Amazon MTurk",
    ],
  },
  {
    id: 0,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/1200px-Salesforce.com_logo.svg.png",
    role: "Data Insights Intern",
    company: "Salesforce",
    date: "May 2023 - Aug 2023",
    desc: "Built data products for Revenue Operations ($3.1B annually)",
    skills: [
      "Tableau",
      "Figma",
      "Quip",
    ],
  },
  {
    id: 1,
    img: "https://pbs.twimg.com/profile_images/1418096568863891458/YSqJukOh_400x400.jpg",
    role: "Product Management Intern",
    company: "Firstly (Ed-Tech Startup)",
    date: "Jan 2023 - May 2023",
    desc: "Built and implemented V2 of flagship product to advance upward mobility in first-gen students.",
    skills: [
      "Airtable",
      "Zapier",
      "Miro"
    ],
  },
  /*{
  {
    id: 4,
    img: "https://static.vecteezy.com/system/resources/thumbnails/011/801/488/small/eps10-black-podium-with-microphone-abstract-solid-icon-isolated-on-white-background-podium-rostrum-symbol-in-a-simple-flat-trendy-modern-style-for-your-website-design-logo-and-mobile-app-vector.jpg",
    role: "Public Speaking Coach",
    company: "San Marino Competitive Speech Team",
    date: "Aug 2020 - Present",
    desc: "3x Speech League Champion; National Tournament Finalist (Top 0.1%), #19 in CA. Mentored 50+ members; 3 became league champions, 2 qualified for nationals; Spearheaded summer programs and classes coaching 30+ students public speaking and improvisation workshops.",
    skills: [
      "Leadership",
      "Technical Communication",
      "Public Speaking",
      "Storytelling",
    ],
  },
  {
    id: 2,
    img: "https://upload.wikimedia.org/wikipedia/en/e/e5/Boy_Scouts_of_America_corporate_trademark.svg",
    role: "Eagle Scout",
    company: "Scouts of America",
    date: "May 2016 - Jan 2024",
    desc: "Taught 40+ scouts outdoor skills; spearheaded meetings, led 3 service projects and 5+ camping excursions; attended National Youth Leadership Training; Eagle Project: Coordinated team of 14 scouts to create cat beds for animal shelter; processed 30,000+ sq in of fabric",
    skills: [
      "Leadership",
      "Team Bonding",
      "Communication",
      "Teaching",
      "Operations",
    ],
  },
  {
    id: 5,
    img: "https://pbs.twimg.com/profile_images/927249066223738880/PhZnjEpJ_400x400.jpg",
    role: "Computer Aided Design Mentor",
    company: "FIRST Robotics Team 1160",
    date: "Aug 2020 - Present",
    desc: "Instructed biweekly work sessions teaching 6th-12th grade students 3D Modeling; Collaborated with 100+ global teams to formulate competition strategies; Raised $7K+ for education nonprofits; coached team at competitions; Hosted 6-week computer-aided design summer camp for incoming freshman.",
    skills: [
      "3D Modeling",
      "Leadership",
      "Technical Communication",
      "Teaching",
    ],
  },
  {
    id: 2,
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/YouTube_social_white_square_%282017%29.svg/768px-YouTube_social_white_square_%282017%29.svg.png",
    role: "Youtube Cinematic Content Creator",
    company: "Chuwae Edits",
    date: "Jun 2021 - Present",
    desc: "Managed and edited videos for two cinmematic video channels. Analyzed optimized SEO strategies, trends, and thumbnails increasing viewership by 33%. Managed monetization, developed content calendars, produced 20+ videos. Garnered 1,000+ followers, 10,000+ views across platforms, global audience (Asia, UK, US).",
    skills: [
      "Video Editing",
      "Storyboarding",
      "Content Creation",
      "SEO",
      "Marketing",
    ],
  },
  {
    id: 3,
    img: "https://upload.wikimedia.org/wikipedia/en/thumb/0/0e/University_of_California%2C_Irvine_seal.svg/1920px-University_of_California%2C_Irvine_seal.svg.png",
    role: "Student Researcher",
    company: "California State Summer School for Mathematics and Science (COSMOS)",
    date: "Jun 2022 - Aug 2022",
    desc: "Coded a program for Crystal Cove State beach to track 20+ years of sea levels based on tide variation. Experimented with internal designs of nano-scale water purification. Final Project: designed and manufactured a sustainable algae skylight for biofuel harvesting; researched its application to energy consumption & lowering household temps.",
    skills: [
      "Engineering",
      "Programming",
      "Computer Aided Design",
      "3D Printing",
    ],
    doc: "https://i.ibb.co/gb0Yd26/Screenshot-2024-02-05-at-10-26-36-PM.png",
  },
}*/
];
export const education = [
  {
    id: 0,
    img: "https://3.files.edl.io/0bfb/22/11/02/190427-7e773200-a6eb-490a-9adb-d4d086387aa8.png",
    school: "UC Berkeley",
    date: "Aug 2020 - May 2024",
    grade: "4.0 GPA",
    desc: "I am currently pursuing a Bachelor's degree in Industrial Engineering and Computer Science. 34 ACT, Titan Elite Scholar Athlete, 3-Year Varsity Sports Member. Clubs: Speech and Debate, FIRST Robotics, Track and Field, Business Professionals of America.",
    degree: "B.S. Industrial Engineering & Operations Research (IEOR), Minors in Computer Science & Data Science",
  },
  {
    id: 1,
    img: "https://i.pinimg.com/originals/5b/49/f8/5b49f80da12e639ff39eaadb28c3ddaa.png",
    school: "California Institute of Technology",
    date: "Aug 2022 - Apr 2023",
    grade: "A",
    desc: "Researched the applications of dual-phase materials through Hydrogel Infusion Additive Manufacturing (HIAM) under professor Julia Greer at Caltech. Worked with Photopolymerization 3D printing technology to print hydrogels using resin printing and researched the applications of dual phase materials.",
    degree: "Student Researcher",
  },
  {
    id: 2,
    img: "https://upload.wikimedia.org/wikipedia/en/thumb/0/0e/University_of_California%2C_Irvine_seal.svg/1920px-University_of_California%2C_Irvine_seal.svg.png",
    school: "University of California, Irvine",
    date: "Jun 2022 - Aug 2022",
    grade: "4.0 GPA",
    desc: "Attended the COSMOS Program, a 4-week intensive STEM residential research program. Studied civil and environmental engineering under Professor Garrett Struckhoff and Joel Lanning. Final Project: Designed and manufactured a sustainable algae skylight for biofuel harvesting on consumer homes.",
    degree: "California State Summer School for Mathematics and Science (COSMOS)",
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1691181448873.jpeg?alt=media&token=ee85eb8f-7247-43cd-9a1d-ce9f58ea62a6",
  },
];

export const projects = [
  {
    id: 6,
    title: "on generalists - part 1",
    date: "July 2024",
    description:
      "optimizing for range",
    image:
      "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F0451992f-e35e-41a8-acdb-59e589b5b408_600x362.jpeg",
    tags: ['Braindumping'],
    category: "Essay",
    link: "https://marcuschua.substack.com/p/on-generalists-part-1",
    github: "https://marcuschua.substack.com/p/genesis-on-enabling-next-level-personalization",
    content: "https://marcuschua.substack.com/p/on-generalists-part-1",

    webapp: "https://drive.google.com/file/d/1GICgVj4WkiPDnu2s1gdoMsBWeT1v21Aj/view?usp=sharing",
  },
  {
    id:5,
    title: "1:1's with ourselves",
    date: "June 2024",
    description:
      "on playing solo",
    image:
      "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F38e9ad6f-08b1-48c5-96dd-ed6ca8b2c3aa_1920x1018.png",
    tags: ['Braindumping'],
    category: "Essay",
    link: "https://marcuschua.substack.com/p/11s-with-ourselves",
    github: "https://marcuschua.substack.com/p/genesis-on-enabling-next-level-personalization",
    content: "https://marcuschua.substack.com/p/11s-with-ourselves",

    webapp: "https://drive.google.com/file/d/1GICgVj4WkiPDnu2s1gdoMsBWeT1v21Aj/view?usp=sharing",
  },
  {
    id: 4,
    title: "a look in the mirror",
    date: "May 2024",
    description:
      "if Berkeley's logo can change, why can't we?",
    image:
      "https://www.shutterstock.com/image-vector/vector-illustration-chess-pawn-casting-600nw-1885098667.jpg",
    tags: ['Braindumping'],
    category: "Essay",
    link: "https://marcuschua.substack.com/p/a-look-in-the-mirror",
    github: "https://marcuschua.substack.com/p/genesis-on-enabling-next-level-personalization",
    content: "https://marcuschua.substack.com/p/a-look-in-the-mirror",
    webapp: "https://drive.google.com/file/d/1GICgVj4WkiPDnu2s1gdoMsBWeT1v21Aj/view?usp=sharing",
  },
  {
    id: 3,
    title: "On Gamma - Presentations",
    date: "March 2024",
    description:
      "Unlocking Enterprise Personalization for the Anti-PowerPoint",
    image:
      "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F663a4da0-3fec-4ca1-8587-2fbe078c45cf_1400x700.png",
    tags: ['Genesis', "Consumer Tech", 'Generative AI', 'Productivity'],
    category: "Case Study",
    link: "https://marcuschua.substack.com/p/genesis-on-enabling-next-level-personalization",
    github: "https://marcuschua.substack.com/p/genesis-on-enabling-next-level-personalization",
    content: "https://marcuschua.substack.com/p/genesis-on-enabling-next-level-personalization",

    webapp: "https://drive.google.com/file/d/1GICgVj4WkiPDnu2s1gdoMsBWeT1v21Aj/view?usp=sharing",
  },
  {
    id: 2,
    title: "On Spotify - Playlisting",
    date: "March 2024",
    description:
      "Elevating the Playlisting Experience for Emerging Artists",
    image:
      "https://substackcdn.com/image/fetch/w_1456,c_limit,f_webp,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F824b8eb9-81a9-49f3-b491-470807d52f8a_1920x1080.png",
    tags: [
      'Genesis', "Consumer Tech", 'Entertainment',
    ],
    category: "Case Study",
    github: "https://www.sotira.app/dashboard",
    webapp: "https://www.sotira.app/dashboard",
  },
  {
    id: 1,
    title: "offense & defense",
    date: "December 2023",
    description:
      "A discussion of connection, presence, and writing.",
    image:
      "https://static.wixstatic.com/media/8a62f1_45e3272049fa419db68d43a92fbde751~mv2.jpg/v1/fill/w_1000,h_667,al_c,q_85,usm_0.66_1.00_0.01/8a62f1_45e3272049fa419db68d43a92fbde751~mv2.jpg",
    tags: ["Braindumping"],
    category: "Essay",
    github: "https://www.loom.com/share/e33bc18176e44f50b662f3954177b2cf?sid=a8581709-5acf-4848-b693-d949aa9cd307",
    content: "https://marcuschua.substack.com/p/offense-and-defense",
    webapp: "https://docs.google.com/presentation/d/1qU1EQ8tyiowu3818KHie_A3apaW8QtbrgI_0KSjHYQQ/edit?usp=sharing",
  }
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];


{/*
  {
    id: 1,
    title: "TKSLife",
    date: "Nov 2023",
    description:
      "Practiced the beginning end of the product ideation process through a redesign of the TKS life platform. Analyzed the problems with the current platform (analysis, user interviews, pain points), drew a wireframe of the possible design, before creating an interactive mockup in Figma to visualize the final result.",
    image:
      "https://www.notion.so/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2Fb179d7b7-8b31-42eb-82eb-cc2b1d31a027%2F464d3e8c-ee2f-45e9-841b-dd1d6067b312%2FUntitled.png?table=block&id=f3443a33-0896-4c03-97bb-e1c347553c44&spaceId=b179d7b7-8b31-42eb-82eb-cc2b1d31a027&width=2000&userId=b4da4129-1870-4afc-b5b3-089e826396d6&cache=v2",
    tags: [
      "React Js",
      "MongoDb",
      "Node Js",
      "Express Js",
      "Redux",
      "NodeMailer",
    ],
    category: "web app",
    github: "https://nicholaschua.notion.site/Case-Study-TKS-Life-Portfolio-Page-Nicholas-Chua-c6f1d829666c4abfae9365d93d6cca73",
    webapp: "https://tks.life/dashboard",
  },
  {
    id: 3,
    title: "Easy Reader",
    date: "Oct 2023",
    description:
      "Easy Reader is an app synced with Meta’s Quest Headset to help dyslexic students translate text. Using the K-Nearest Neighbors Algorithm, the app can memorize student habits and automatically translate text based on the users text manipulation patterns.",
    image:
      "https://www.notion.so/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2Fb179d7b7-8b31-42eb-82eb-cc2b1d31a027%2Fddcbdad1-7f92-4ebc-95a9-3f5baf869c53%2FScreenshot_2024-02-06_at_3.16.53_PM.png?table=block&id=af50fdef-4e1c-42c5-a40f-4650754e9850&spaceId=b179d7b7-8b31-42eb-82eb-cc2b1d31a027&width=2000&userId=b4da4129-1870-4afc-b5b3-089e826396d6&cache=v2",
    tags: ["Kotlin", "MVVM", "Room Database", "Google Drive Cloud API"],
    category: "android app",
    github: "https://www.figma.com/file/1RkB2I9ITFLJKlPj49P2jJ/TKS-HACKATHON-MOCKUP?type=design&node-id=0-1&mode=design",
    webapp: "https://youtube.com/shorts/0xkYtJVvwdE?si=4Kzkye2X8kl4tiV0",
  },
  {
    id: 11,
    title: "The Well",
    date: "May 2023 - Present",
    description:
      "The Well is a digital mental health database that matches students in schools with the best online resources in any domain they may struggle with. By creating online digital profiles, each school can have their own “Well” of resources allowing students to get quick access to help instead of scrolling through multitudes of underwhelming resources.",
    image:
      "https://www.notion.so/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2Fb179d7b7-8b31-42eb-82eb-cc2b1d31a027%2Ff22d8c0c-fb61-47fe-91fe-e16d7fe03616%2FScreenshot_2024-02-02_at_2.46.14_PM.png?table=block&id=b9edf75a-43fa-4e30-930c-6b7c57c7fa3c&spaceId=b179d7b7-8b31-42eb-82eb-cc2b1d31a027&width=2000&userId=b4da4129-1870-4afc-b5b3-089e826396d6&cache=v2",
    tags: [
      "React Js",
      "PostgressSQL",
      "Node Js",
      "Express Js",
      "Redux",
      "React Flow",
    ],
    category: "web app",
    github: "https://www.welfie.com/thewell",
    webapp: "https://dev.welfie.com/signin",
  },*/}